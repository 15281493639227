<template>
    <!-- 个人分销提现 -->
    <div class="pageContol listWrap templateList">
        <div class="framePage">
            <div class="framePage-title">
                <span>
                <em>当前位置：</em>
                <a href="javascript:;">分销管理</a>
                <i>></i>
                <a href="javascript:;" class="cur-a">分销提现</a>
                </span>
            </div>
            <div class="framePage-body">
                <!-- 搜索区域 -->
                <div class="operationControl" style="display: flex; flex-direction: column; align-items: flex-start">
                    <!-- 搜索区域 -->
                    <div class="searchbox">
                        <div title="申请时间" class="searchboxItem ci-full">
                            <span class="itemLabel">申请时间:</span>
                            <el-date-picker
                                @change="datePickerChange"
                                v-model="searchForm.paymentTime"
                                type="daterange"
                                size="small"
                                value-format="yyyy-MM-dd"
                                clearable
                                range-separator="至"
                                start-placeholder="开始时间"
                                end-placeholder="结束时间">
                            </el-date-picker>
                        </div>
                        <div title="分销人" class="searchboxItem ci-full">
                            <span class="itemLabel">分销人:</span>
                            <el-input style="width:100%" v-model="searchForm.mobile" @input="filter('mobile')" placeholder="请输入分销人手机号" type="text" clearable size="small"/>
                        </div>
                        <div title="提现状态" class="searchboxItem ci-full">
                            <span class="itemLabel">提现状态:</span>
                            <el-select v-model="searchForm.topupState" clearable placeholder="请选择提现状态" style="width:100%" size="small">
                                <el-option
                                    v-for="item in withdrawalStatus"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="searchboxItem">
                            <el-button type="primary" class="bgc-bv" round @click="searchData(1)">查询</el-button>
                            <el-button type="primary" class="bgc-bv" round @click="exportData">导出</el-button>
                        </div>
                    </div>
                    <div class="searchbox" style="margin-top:20px;">
                        <div class="searchboxItem">
                            <span class="itemLabel">提现金额汇总：￥{{amountTotal}}</span>
                        </div>
                    </div>
                </div>
                <!-- 列表区域 -->
                <div class="framePage-scroll">
                    <div class="ovy-a">
                        <el-table
                            ref="multipleTable"
                            :data="dataList"
                            :height="tableHeight"
                            size="small"
                            tooltip-effect="dark"
                            style="width: 100%"
                            :header-cell-style="tableHeader"
                            stripe
                        >
                            <el-table-column
                                label="序号"
                                align="center"
                                type="index"
                                :index="indexMethod"
                                width="50"
                            />
                            <el-table-column
                                label="申请人姓名"
                                align="left"
                                prop="userName"
                                show-overflow-tooltip
                            />
                            <el-table-column
                                label="手机号码"
                                align="left"
                                prop="mobile"
                                show-overflow-tooltip
                            />
                            <el-table-column
                                label="收款方式"
                                align="left"
                                prop="topupMode"
                                show-overflow-tooltip
                            />
                            <el-table-column
                                label="提现金额"
                                align="left"
                                prop="amountMoneyStr"
                                show-overflow-tooltip
                            >
                                <template v-slot="{row}">
                                    ￥{{row.amountMoneyStr}}
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="提现状态"
                                align="left"
                                prop="topupState"
                                show-overflow-tooltip>
                            </el-table-column>
                            <el-table-column
                                label="申请时间"
                                min-width="120px"
                                align="left"
                                prop="createTimeStr"
                                show-overflow-tooltip
                            />
                            <Empty slot="empty" />
                        </el-table>
                    </div>
                </div>
                <!-- 分页器 -->
                <PageNum :apiData="pagination" @sizeChange="sizeChange" @getData="currentChange"/>
            </div>
        </div>
    </div>
</template>

<script>
    import Empty from "@/components/Empty.vue";
    import PageNum from "@/components/PageNum.vue";
    import List from "@/mixins/List";
    import { mapGetters } from "vuex";
    export default {
        name:"personalDistributionWithdrawal",
        components:{
            Empty,
            PageNum
        },
        mixins:[List],
        created(){
            this.initData();
        },
        data(){
            return{
                // 列表数据
                dataList:[],
                // 提现金额汇总
                amountTotal:0,
                // 查询表单
                searchForm:{
                    mobile:"", //分销人手机号
                    paymentTime:[], //申请时间
                    applicationDateFront:"", //开始时间
                    applicationDateBack:"", //结束时间
                    topupState:"", // 提现状态
                },
                // 分页器数据
                pagination:{
                    current:1,
                    size:10,
                    total:0
                },
                // 选择框数据
                withdrawalStatus:[]
            }
        },
        computed:{
            ...mapGetters({
                downloadItems: "getDownloadItems",
            })
        },
        methods:{
          // 序号
          indexMethod(index) {
            return (this.pagination.current - 1) * this.pagination.size + index + 1;
          },
            // 初始化数据
            initData(){
                this.getDictionaryData();
                this.getDataList();
                this.getSummaryWithdrawalAmount();
            },
            // 查询数据
            searchData(query){
                this.getDataList(query);
                this.getSummaryWithdrawalAmount();
            },
            // 获取数据列表
            getDataList(query){
                const {searchForm,pagination}=this;
                if(typeof query==="number") pagination.current=query;
                // 请求参数
                const data={
                    ...searchForm,
                    pageNum :pagination.current,
                    pageSize:pagination.size
                }
                delete data.paymentTime;
                // 发起请求
                this.$post("/userDistribution/topupLog/topupLogList",data,3000, true, 2).then(res=>{
                    this.dataList=res?.data?.list||[];
                    pagination.total=res?.data?.total||0;
                }).catch(e=>{
                    console.error("获取列表数据出错",e);
                });
            },
            // 获取提现金额汇总
            getSummaryWithdrawalAmount(){
                const {searchForm}=this;
                // 请求参数
                const data={
                    ...searchForm,
                }
                // 删除无用入参
                delete data.paymentTime;
                this.$post("/userDistribution/topupLog/topupAmountTotal",data,3000,true,2).then(res=>{
                    this.amountTotal=res?.data?.amountTotal||0;
                }).catch(e=>{
                    console.error("获取汇总金额出错",e);
                });
            },
            // 导出
            exportData(){
                const {searchForm}=this;
                // 请求参数
                const data={
                    ...searchForm,
                }
                // 删除无用入参
                delete data.paymentTime;
                this.$post("/userDistribution/topupLog/topupLogListExport",data,3000,true,2).then(res=>{
                    if (!this.downloadItems.includes(res.data.taskId)) {
                        this.$store.dispatch("pushDownloadItems", res.data.taskId);
                    } else {
                        this.$message.warning("[" + res.data.fileName + "]已经申请下载,请耐心等待");
                    }
                }).catch(e=>{
                    console.error("导出列表出错",e);
                });
            },
            // 获取字典数据
            getDictionaryData(){
                const {withdrawalStatus}=this;
                const obj=this.$setDictionary("TOPUP_STATE", "list");
                Object.keys(obj).forEach(key=>{
                    withdrawalStatus.push({
                        label:obj[key],
                        value:key
                    });
                });
            },
            // 时间选择器改变
            datePickerChange(value){
                const {searchForm}=this;
                if(value){
                    searchForm.applicationDateFront=value[0];
                    searchForm.applicationDateBack=value[1];
                }else{
                    searchForm.applicationDateFront="";
                    searchForm.applicationDateBack="";
                }
            },
            // 分页器当前页改变
            currentChange(val){
                const {pagination}=this;
                pagination.current=val;
                this.searchData();
            },
            // 分页器条数改变
            sizeChange(val){
                const {pagination}=this;
                pagination.size=val;
                this.searchData();
            },
            // 过滤非法数据
            filter(key){
                const {searchForm}=this;
                const value=searchForm[key].replace(/\D/g,"");
                searchForm[key]=value;
            },
            // 设置表格高度
            getTableHeight(opDom = false, page = true) {
                let tHeight = window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 4) * 16;
                if (opDom)  tHeight -= 40 + 0.675 * 16 + 1;
                if (page)  tHeight -= 52;
                this.tableHeight = tHeight;
            }
        }
    }   
</script>

<style lang="less" scoped>

</style>